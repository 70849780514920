
	import { Vue, Component, Mixins } from 'vue-property-decorator';
	import PixcapLogo from '@pixcap/ui-library/components/Assets/PixcapLogo.vue';
	import UserInfoAvatar from '@pixcap/ui-core/components/account/common/UserInfoAvatar.vue';
	import UserCart from '@pixcap/ui-core/components/account/common/UserCart.vue';
	import GoogleAutomaticLogin from '@pixcap/ui-core/components/account/common/GoogleAutomaticLogin.vue';
	import Button from '@pixcap/ui-library/components/Button/Button2.vue';
	import ServerDownloadPopup from '@pixcap/ui-core/components/download-queue/ServerDownloadPopup.vue';
	import AuthenticationModalMixin from '@pixcap/ui-core/components/account/AuthenticationModalMixin';
	import HeadingText from '@pixcap/ui-library/components/Typography/HeadingText.vue';
	import IconArrowDown from '@pixcap/ui-library/components/Icons/IconArrowDown.vue';
	import IconCheck from '@pixcap/ui-library/components/Icons/IconCheck.vue';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import PopupMenu from '@pixcap/ui-library/components/PopupMenu.vue';
	import BlogHeaderNavbarItem from '@/components/layouts/blog/BlogHeaderNavbarItem.vue';
	import { PixcapArticle } from '@/models/store/blog.interface';
	import { actionsWrapper as BlogActions } from '@/store/blog/wrapper';

	@Component({
		name: 'BlogHeader',
		components: {
			BlogHeaderNavbarItem,
			PopupMenu,
			BodyText,
			IconCheck,
			IconArrowDown,
			HeadingText,
			ServerDownloadPopup,
			Button,
			GoogleAutomaticLogin,
			UserCart,
			UserInfoAvatar,
			PixcapLogo,
		},
	})
	export default class BlogHeader extends Mixins(AuthenticationModalMixin) {
		articles: PixcapArticle[] = [];

		hamburgerMenus = [
			{ name: 'AI 3D Model Generator', url: 'https://pixcap.com/tools/ai-3d-model-generator' },
			{ name: 'AI Girl Generator', url: 'https://pixcap.com/tools/ai-girl-generator' },
			{ name: 'AI Background Generator', url: 'https://pixcap.com/tools/ai-background-generator' },
			{ name: 'AI Icon Generator', url: 'https://pixcap.com/tools/ai-icon-generator' },
			{ name: '3D Icons Maker', url: 'https://pixcap.com/tools/3d-icons' },
			{ name: 'Animated Icon', url: 'https://pixcap.com/tools/animated-icons' },
			{ name: 'Pixcap AI', url: 'https://pixcap.com/ai' },
			{ name: 'Character Library', url: 'https://pixcap.com/character-library' },
			{ name: 'Material Library', url: 'https://pixcap.com/material-library' },
			{ name: 'Animated Mockups', url: 'https://pixcap.com/animated-mockups-marketing' },
			{ name: 'Blogs', url: 'https://pixcap.com/blog' },
			{ name: 'Pricing', url: 'https://pixcap.com/pricing' },
		];

		showHamburgerMenu = false;

		get latestArticles() {
			if (!this.articles?.length) return [];

			return this.articles?.map((article) => {
				return {
					name: article.title,
					thumbnail: article.thumbnailUrl,
					url: this.$router.resolve({
						name: 'Article',
						params: {
							articleSlug: article.slug,
						},
					}).href,
				};
			});
		}

		async created() {
			const [articles, _] = await BlogActions.getArticles(this.$store, {});
			this.articles = articles;
		}
	}
