import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { DEFAULT_APP_LOCALE } from '@/constants/app.constants';
import messages from '@/lang';

Vue.use(VueI18n);
export default new VueI18n({
	locale: DEFAULT_APP_LOCALE,
	fallbackLocale: DEFAULT_APP_LOCALE,
	messages,
});
