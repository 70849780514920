
	import { Component, Vue, Prop } from 'vue-property-decorator';
	import { mapState } from 'vuex';
	import FilterInput from '@/components/pixcap-library/FilterInput.vue';
	import { AppState, NAMESPACE as APP_NAMESPACE } from '@pixcap/ui-core/models/store/app.interface';
	import IconGlobe from '@pixcap/ui-library/components/Icons/IconGlobe.vue';
	import { mutationsWrapper as OrganismMutations } from '@/store/organism/wrapper';

	@Component({
		name: 'AppLocaleSwitcher',
		components: {
			FilterInput,
			IconGlobe,
		},
		computed: {
			...mapState(APP_NAMESPACE, {
				locale: (state: AppState) => state.locale,
			}),
		},
	})
	export default class AppLocaleSwitcher extends Vue {
		@Prop({ default: null }) isProActive: boolean;
		@Prop({ default: 'right' }) openPosition: string;
		@Prop({ default: 'default' }) size: 'small' | 'default';

		locale: AppState['locale'];
		IconGlobe = IconGlobe;

		filterOptions = [
			{
				name: 'English',
				value: 'en',
			},
			{
				name: 'Español',
				value: 'es',
			},
			{
				name: 'Deutsch',
				value: 'de',
			},
		];

		get activeOption() {
			const filterOptions = this.filterOptions;
			return filterOptions.find((option) => option.value === this.locale) || filterOptions[0];
		}

		handleOnShowMenu(isShow) {
			this.$emit('isAppLocaleActive', isShow);
		}

		handleChangeFilter(value: string) {
			this.$i18n.locale = value;
			const currentRoute = this.$route;
			delete currentRoute.params.locale;
			const route = this.$router.resolve({
				name: currentRoute.name,
				params: {
					...currentRoute.params,
				},
				query: currentRoute.query,
			});
			if (route && window) {
				window.location.href = route.href;
			}
		}
	}
