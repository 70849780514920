
	import { Component, Mixins } from 'vue-property-decorator';
	import { mutationsWrapper as UserMutations } from '@pixcap/ui-core/store/user';
	import { MY_ACCOUNT_TAB } from '@pixcap/ui-core/models/store/user.interface';
	import { actionsWrapper as AuthActions } from '@pixcap/ui-core/store/auth';
	import { mapState } from 'vuex';
	import { mutationsWrapper as LibraryMutations } from '@/store/pixcapassets/wrapper';
	import { LIBRARY_TAB } from '@/models/store/pixcapassets.interface';
	import CaptionText from '@pixcap/ui-library/components/Typography/CaptionText.vue';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import Button from '@pixcap/ui-library/components/Button/Button2.vue';
	import IconGlobe from '@pixcap/ui-library/components/Icons/IconGlobe.vue';
	import AuthenticationModalMixin from '@pixcap/ui-core/components/account/AuthenticationModalMixin';
	import { AppState, NAMESPACE as APP_NAMESPACE } from '@pixcap/ui-core/models/store/app.interface';
	import IconCaretLeft from '@pixcap/ui-library/components/Icons/IconCaretLeft.vue';
	import IconCheck from '@pixcap/ui-library/components/Icons/IconCheck.vue';
	import Icon3DIcons from '@pixcap/ui-library/components/Icons/explore-header/Icon3DIcons.vue';
	import IconAccountSettings from '@pixcap/ui-library/components/Icons/explore-header/IconAccountSettings.vue';
	import IconSubscription from '@pixcap/ui-library/components/Icons/explore-header/IconSubscription.vue';
	import IconDownloads from '@pixcap/ui-library/components/Icons/explore-header/IconDownloads.vue';
	import IconProjects from '@pixcap/ui-library/components/Icons/explore-header/IconProjects.vue';
	import IconMockups from '@pixcap/ui-library/components/Icons/explore-header/IconMockups.vue';
	import Icon3DCharacters from '@pixcap/ui-library/components/Icons/explore-header/Icon3DCharacters.vue';
	import IconPricing from '@pixcap/ui-library/components/Icons/explore-header/IconPricing.vue';
	import IconLanguageChanger from '@pixcap/ui-library/components/Icons/explore-header/IconLanguageChanger.vue';

	@Component({
		name: 'ExploreHeaderHamburger',
		components: { IconCheck, IconCaretLeft, IconGlobe, Button, BodyText, CaptionText, IconPricing, IconLanguageChanger },
		computed: {
			...mapState(APP_NAMESPACE, {
				locale: (state: AppState) => state.locale,
			}),
		},
	})
	export default class ExploreHeaderHamburger extends Mixins(AuthenticationModalMixin) {
		locale: AppState['locale'];

		isMenuOpen = false;
		showAppLocalse = false;

		get navigationItems() {
			return [
				{
					title: '3D Assets',
					content: [
						{
							title: '3D Icons',
							icon: Icon3DIcons,
							handler: () => {
								this.navigationItemHandler(LIBRARY_TAB.THREE_D_PACKS);
							},
						},
						{
							title: '3D Characters',
							icon: Icon3DCharacters,
							handler: () => {
								this.navigationItemHandler(LIBRARY_TAB.THREE_D_CHARACTERS);
							},
						},
						{
							title: 'Mockups',
							icon: IconMockups,
							handler: () => {
								this.navigationItemHandler(LIBRARY_TAB.MOCKUPS);
							},
						},
					],
				},
				this.isAuthenticated
					? {
							title: 'Account',
							content: [
								{
									title: 'Projects',
									icon: IconProjects,
									handler: () => {
										this.close();
										this.$router.push({ name: 'ProjectsPage' });
									},
								},
								{
									title: 'Downloads',
									icon: IconDownloads,
									handler: () => {
										this.close();
										this.$router.push({ name: 'AssetsPage' });
									},
								},
								{
									title: 'Account Settings',
									icon: IconAccountSettings,
									handler: () => {
										this.navigationItemHandler(LIBRARY_TAB.MOCKUPS);
									},
								},
								{
									title: 'Subscription',
									icon: IconSubscription,
									handler: () => {
										this.handleNavigateSubscription();
									},
								},
							],
					  }
					: undefined,
			];
		}

		languageOptions = [
			{
				name: 'English',
				value: 'en',
			},
			{
				name: 'Español',
				value: 'es',
			},
			{
				name: 'Deutsch',
				value: 'de',
			},
		];

		get activeOption() {
			const filterOptions = this.languageOptions;
			return filterOptions.find((option) => option.value === this.locale) || filterOptions[0];
		}

		toggleMenu() {
			if (this.isMenuOpen) {
				this.close();
			} else {
				this.isMenuOpen = true;
			}
		}

		handleOpenAppLocale() {
			this.showAppLocalse = true;
		}

		navigationItemHandler(key) {
			this.close();
			LibraryMutations.setExplorerTab(this.$store, key);
			if (key === LIBRARY_TAB.THREE_D_CHARACTERS) {
				LibraryMutations.updateShowOnlyAnimatedFiles(this.$store, null);
			}
			if (this.$route.name === 'LibraryPage') {
				const libraryRoute = this.$router.resolve(key);
				window.history.replaceState({}, '', libraryRoute.href);
			} else {
				this.$router.push({ name: 'LibraryPage', params: { tab: key } });
			}
		}

		navigate(link) {
			this.close();
			window.location.href = link;
		}

		handleNavigateSubscription() {
			this.close();
			this.$router.push({ name: 'UserSettingsPage' });
			UserMutations.setActiveMyAccountTab(this.$store, MY_ACCOUNT_TAB.SUBSCRIPTION);
		}

		handleLogOut() {
			this.close();
			AuthActions.logout(this.$store, { sessionChange: false });
		}

		handleChangeLanguage(language) {
			this.$i18n.locale = language.value;
			const currentRoute = this.$route;
			delete currentRoute.params.locale;
			const route = this.$router.resolve({
				name: currentRoute.name,
				params: {
					...currentRoute.params,
				},
				query: currentRoute.query,
			});
			if (route && window) {
				window.location.href = route.href;
			}
		}

		close() {
			this.isMenuOpen = false;
			this.showAppLocalse = false;
		}
	}
